@import 'layouts/theme.scss';

.training-phase-steps {
  .ant-steps-item-process {
    .ant-steps-item-title,
    .ant-steps-item-icon span {
      @include themify($themes) {
        color: themed('secondary-color') !important;
      }
    }
  }
}
