$primary-color: #00918c;
$secondary-color: #fe7a3d;

$d-primary-color: #5a6eac;
$d-secondary-color: darken(#fe7a3d, 20%);

//color name source: https://www.color-hex.com/, https://www.htmlcsscolor.com/
$white: #ffffff;
$black: #000000;
$gray77: #c4c4c4;
$gray90: #e5e5e5;
$gray60: #d2d2d2;
$red: #e42b2b;
$light-red: #fee1e1;
$light-grey: #c4c4c4;
$lighter-grey: #efefef;
$dark-grey: #222222;
$lightcyan-color: #e6f4f4;
$matterhorn: #515151;
$nobel: #979797;
$color-disable: #f5f5f5;

$blue: #3b7bbe;
$chablis: #ffe7dc;
$link-color: #23527c;
$gray-color: #ececec;

$success: $primary-color;
$danger: $red;
$orange: #fe7a3d;
$danger2: #e42b2b;
/* Text */
$text-primary-color: $dark-grey;
$text-secondary-color: $matterhorn;
$text-info-color: $blue;
$text-danger-color: $danger;
$text-muted-color: $nobel;

/* Border */
$border-primary-color: $gray77;

/* Background */
$background-gray: #f6f6f6;
$background-primary-color: $white;
$background-secondary-color: $dark-grey;
$background-active-color: $lightcyan-color;
$background-overlay-color: rgba($dark-grey, 0.75);

/* Button */
$button-radius: 3px;
$button-primary-background-color: $primary-color;
$button-primary-text-color: $white;
$button-secondary-background-color: $gray90;
$button-secondary-text-color: $matterhorn;

/* Menu */
$main-menu-text-color: $matterhorn;
$main-menu-text-color-active: $secondary-color;

$theme-colors: (
        'primary': (
                'base': $primary-color,
                'light': $primary-color,
                'dark': $d-primary-color,
        ),
        'secondary': (
                'base': $secondary-color,
                'light': $secondary-color,
                'dark': $d-primary-color,
        ),
        'background': (
                'base': $white,
                'light': $white,
                'dark': $white,
        ),
);

@function theme-color($key: 'primary', $variant: 'base') {
  $map: map-get($theme-colors, $key);
  @return map-get($map, $variant);
}

$vt: (
        vt-light: (
          // common
                primary-color: $primary-color,
                secondary-color: $secondary-color,
                light: $white,
                dark: $black,
                gray60: $gray60,
                gray77: $gray77,
                gray90: $gray90,
                light-grey: $light-grey,
                lighter-grey: $lighter-grey,
                dark-grey: $dark-grey,
                lightcyan-color: $lightcyan-color,
                color-disable: $color-disable,
                light-red: $light-red,
                matterhorn: $matterhorn,
                nobel: $nobel,
                blue-color: $blue,
                chablis: $chablis,
                success: $primary-color,
                danger: $red,
                orange: $orange,
                link-color: $link-color,
                gray-color: $gray-color,
                danger2: $danger2,
                text-primary-color: $dark-grey,
                text-secondary-color: $matterhorn,
                text-info-color: $blue,
                text-danger-color: $danger,
                text-muted-color: $nobel,
                border-primary-color: $gray77,
                background-primary-color: $white,
                background-secondary-color: $dark-grey,
                background-active-color: $lightcyan-color,
                background-overlay-color: rgba($dark-grey, 0.75),
                button-radius: 3px,
                button-primary-background-color: $primary-color,
                button-primary-text-color: $white,
                button-secondary-background-color: $gray90,
                button-secondary-text-color: $matterhorn,
          // admin
                a-primary-cl: theme-color(),
                a-second-cl: theme-color('secondary', 'base'),
                a-bg-cl: theme-color('background', 'base'),
                a-bg-cl-hover: theme-color('background', 'base'),
                a-bg-cl-active: theme-color('background', 'base'),
                a-bg-cl-focus: theme-color('background', 'base'),
                a-bg-cl-visited: theme-color('background', 'base'),
                a-text-cl: theme-color(),
                a-text-cl-hover: theme-color(),
                a-text-cl-active: theme-color(),
                a-text-cl-focus: theme-color(),
                a-text-cl-visited: theme-color(),
                a-btn-cl-hover: theme-color(),
                a-btn-cl-active: theme-color(),
                a-btn-cl-focus: theme-color(),
                a-btn-cl-visited: theme-color(),
                a-border-top-menu: 1px solid theme-color(),
          //
                l-main-bg: white,
                l-control: white,
                l-exercise-overview: #fbfbfb,
                l-exercise-overview-border: #e7e7e7,
                l-nav-bg-color: white,
                l-track-vertical: #e7e7e7,
                l-thumb-vertical-bg: rgba(0, 0, 0, 0.2),
                l-nav-item-cl: black,
                l-zone-border: #dddddd,
                l-quiz-conent-icon: black,
                l-quiz-conent-h3: black,
                login-form-indicator: theme-color(),
          //text
                text-cl-hover: theme-color('secondary'),
          // border
                feature-courses-border: 2px solid theme-color(),
                border-bottom-color: 3px solid theme-color(),
          //border-radius: 100%,
          // li
                li-bg-hover: #f2f3f5,
                li-cl-hover: #c4261c,
                li-bg-selected: #ffccc7,
                li-cl-selected: #c4261c,
          //button
                btn-primary-bg: theme-color(),
                btn-text-cl: theme-color('background', 'base'),
                btn-login-bg: theme-color(),
                l-button-nav-bg: transparent,
                l-button-nav-bg-hover: #e7e7e7,
                l-button-nav-bg-hover-1: #e7e7e7,
                l-button-nav-bg-hover-2: #bcbcbc,
                l-button-nav-cl-hover-2: rgba(0, 0, 0, 0.3),
                l-button-nav-cl: white,
                l-button-nav-cl-1: #00bcd4,
                l-button-nav-cl-hover: #fe7a3d,
                l-button-nav-cl-hover-1: rgba(0, 0, 0, 0.3),
                l-button-nav-border: transparent,
                l-button-filled-bg: #e7e7e7,
                l-button-filled-border: #e7e7e7,
                l-button-filled-cl: black,
                l-button-filled-hover-cl: black,
                l-button-filled-hover-bg: #bcbcbc,
                l-button-filled-hover-border: #bcbcbc,
                l-button-filled-border-radius-dimen: 2px,
                l-button-primary-bg: $primary-color,
                l-button-primary-border: theme-color(),
                l-button-primary-cl: $white,
                l-button-primary-hover-cl: theme-color(),
                l-button-primary-hover-bg: $white,
                l-button-primary-hover-border: theme-color(),
                l-button-primary-border-radius-dimen: 3px,
                l-button-secondary-bg: $secondary-color,
                l-button-secondary-cl: $white,
                l-button-secondary-hover-bg: $white,
                l-button-secondary-hover-cl: $secondary-color,
                l-button-bg: $gray90,
                l-button-cl: $matterhorn,
                l-button-hover-bg: $matterhorn,
                l-button-hover-cl: $gray90,
                l-question-button-disable-cl: rgb(174, 174, 173),
          // item menu
                l-menu-item-header-bg: #e7e7e7,
                l-menu-item-header-cl: black,
                l-menu-item-bg-active: #92b773,
                l-menu-item-bg-active-1: #cde3bb,
                l-menu-item-playing-or-next-bg: #c9c9c9,
                l-menu-item-playing-or-next-cl: black,
          //dev
                l-nav-dev-bg: white,
                l-dev-button-bg: transparent,
                l-dev-button-cl: black,
                l-dev-button-bg-active: #e7e7e7,
                l-dev-button-cl-active: black,
          // question
                l-question-cl: $nobel,
                l-question-border-cl: $gray90,
                l-question-clickable-cl: black,
                l-question-hover-bg: #e7e7e7,
                l-question-ticked-bg: #ffe7dc,
                l-question-correct-bg: $primary-color,
                l-question-correct-cl: $white,
                l-question-correct-border-cl: $primary-color,
                l-question-incorrect-bg: $red,
                l-question-incorrect-cl: $white,
                l-question-incorrect-border-cl: $red,
                l-question-done-bg: $blue,
                l-question-done-cl: $white,
                l-question-done-border-cl: $blue,
                l-question-touched-bg: $gray90,
                l-question-touched-border-cl: $gray90,
                l-question-current-bg: #92c36a,
                l-question-current-border-cl: $blue,
                l-question-listening-bg: #1b5e20,
                l-question-save-failed-border-cl: $red,
                l-question-height: 0,
          // summary course
                l-summary-course-session-bg: rgba(224, 224, 224, 0.24),
                menu-top-bg: theme-color(),
                l-summary-course-feature-boxed: white,
                l-course-overview-content-cl: #19191d,
          // in progress
                l-progress-finish: rgba(146, 195, 106, 0.85),
                l-progress-improvement: rgba(239, 140, 0, 0.85),
          //rgba(240, 173, 78, 0.85),
                l-progress-lock: rgba(217, 83, 79, 0.85),
                l-progress-bad: rgba(217, 83, 79, 0.85),
          // PDF
                l-button-pdf-cl: #6d6d6d,
                l-button-pdf-cl-disable: #e0e0e0,
          // comment
                comment-main-cl: #303030,
                comment-action-hover-bg: #ededed,
                active-color: theme-color(),
                active-item-background-color: #e6f4f4,
                active-item-background-border: theme-color(),
                close-button-color: theme-color(),
                complete-icon-background-color: theme-color(),
                course-title-percent-text-color: theme-color(),
                over-deadline-color: red,
                primary-text-color: theme-color(),
                progress-color-learn: theme-color(),
                sco-background-color--hover: #e6f4f4,
                sco-background-color: #e6f4f4,
                sco-text-color: #504e4e,
                tab-background-color: #ffffff,
                tab-text-active-color: theme-color(),
                tab-text-color: #504e4e,
                tab-box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1),
                tiny-progress-color: theme-color(),
                pagination-text-color: theme-color(),
                pagination-active-text-color: #fff,
                pagination-acive-background-color: theme-color(),
          // modal login
                login-button-facebook: #3b5998,
                login-button-google: #dc4e41,
                login-background-footer: #242c42,
                login-color-footer: #95989a,
                login-color-link: #3b7adb,
                login-color-hover-forgot-password: #e13542,
                login-color-title: #272727,
                login-color-support: #f1f2ef,
          // faq-question-answer
                question-content: #242c42,
                question-tie: #e72a2a,
                answer-tie: #4a90e2,
                answer-link: #00ace9,
                border-expand: #8fdd2d,
          //question
                hover-highlight-question: #5ac743,
                status-success: theme-color(),
                status-approved: #096e07,
                status-queued: #808080,
                status-rejected: #ff0000,
                top-banner-temis-dashboard: $primary-color,
                theme-background-color: $white,
        ),
        vt-dark: (
          // common
                primary-color: $d-primary-color,
                secondary-color: $d-secondary-color,
                light: $white,
                dark: $black,
                gray60: $gray60,
                gray77: $gray77,
                gray90: $gray90,
                light-grey: $light-grey,
                lighter-grey: $lighter-grey,
                dark-grey: $dark-grey,
                lightcyan-color: $lightcyan-color,
                color-disable: $color-disable,
                light-red: $light-red,
                matterhorn: $matterhorn,
                nobel: $nobel,
                blue-color: $blue,
                chablis: $chablis,
                success: $primary-color,
                danger: $red,
                orange: $orange,
                link-color: $link-color,
                gray-color: $gray-color,
                danger2: $danger2,
                text-primary-color: $dark-grey,
                text-secondary-color: $matterhorn,
                text-info-color: $blue,
                text-danger-color: $danger,
                text-muted-color: $nobel,
                border-primary-color: $gray77,
                background-primary-color: $white,
                background-secondary-color: $dark-grey,
                background-active-color: $lightcyan-color,
                background-overlay-color: rgba($dark-grey, 0.75),
                button-radius: 3px,
                button-primary-background-color: $primary-color,
                button-primary-text-color: $white,
                button-secondary-background-color: $gray90,
                button-secondary-text-color: $matterhorn,
          // admin
                a-primary-cl: theme-color(),
                a-second-cl: theme-color('secondary', 'base'),
                a-bg-cl: theme-color('background', 'base'),
                a-bg-cl-hover: theme-color('background', 'base'),
                a-bg-cl-active: theme-color('background', 'base'),
                a-bg-cl-focus: theme-color('background', 'base'),
                a-bg-cl-visited: theme-color('background', 'base'),
                a-text-cl: theme-color(),
                a-text-cl-hover: theme-color(),
                a-text-cl-active: theme-color(),
                a-text-cl-focus: theme-color(),
                a-text-cl-visited: theme-color(),
                a-btn-cl-hover: theme-color(),
                a-btn-cl-active: theme-color(),
                a-btn-cl-focus: theme-color(),
                a-btn-cl-visited: theme-color(),
                a-border-top-menu: 1px solid theme-color(),
          //
                l-main-bg: white,
                l-control: white,
                l-exercise-overview: #fbfbfb,
                l-exercise-overview-border: #e7e7e7,
                l-nav-bg-color: white,
                l-track-vertical: #e7e7e7,
                l-thumb-vertical-bg: rgba(0, 0, 0, 0.2),
                l-nav-item-cl: black,
                l-zone-border: #dddddd,
                l-quiz-conent-icon: black,
                l-quiz-conent-h3: black,
                login-form-indicator: theme-color(),
          //text
                text-cl-hover: theme-color('secondary'),
          // border
                feature-courses-border: 2px solid theme-color(),
                border-bottom-color: 3px solid theme-color(),
          //border-radius: 100%,
          // li
                li-bg-hover: #f2f3f5,
                li-cl-hover: #c4261c,
                li-bg-selected: #ffccc7,
                li-cl-selected: #c4261c,
          //button
                btn-primary-bg: theme-color(),
                btn-text-cl: theme-color('background', 'base'),
                btn-login-bg: theme-color(),
                l-button-nav-bg: transparent,
                l-button-nav-bg-hover: #e7e7e7,
                l-button-nav-bg-hover-1: #e7e7e7,
                l-button-nav-bg-hover-2: #bcbcbc,
                l-button-nav-cl-hover-2: rgba(0, 0, 0, 0.3),
                l-button-nav-cl: white,
                l-button-nav-cl-1: #00bcd4,
                l-button-nav-cl-hover: #fe7a3d,
                l-button-nav-cl-hover-1: rgba(0, 0, 0, 0.3),
                l-button-nav-border: transparent,
                l-button-filled-bg: #e7e7e7,
                l-button-filled-border: #e7e7e7,
                l-button-filled-cl: black,
                l-button-filled-hover-cl: black,
                l-button-filled-hover-bg: #bcbcbc,
                l-button-filled-hover-border: #bcbcbc,
                l-button-filled-border-radius-dimen: 2px,
                l-button-primary-bg: $primary-color,
                l-button-primary-border: theme-color(),
                l-button-primary-cl: $white,
                l-button-primary-hover-cl: theme-color(),
                l-button-primary-hover-bg: $white,
                l-button-primary-hover-border: theme-color(),
                l-button-primary-border-radius-dimen: 3px,
                l-button-secondary-bg: $secondary-color,
                l-button-secondary-cl: $white,
                l-button-secondary-hover-bg: $white,
                l-button-secondary-hover-cl: $secondary-color,
                l-button-bg: $gray90,
                l-button-cl: $matterhorn,
                l-button-hover-bg: $matterhorn,
                l-button-hover-cl: $gray90,
                l-question-button-disable-cl: rgb(174, 174, 173),
          // item menu
                l-menu-item-header-bg: #e7e7e7,
                l-menu-item-header-cl: black,
                l-menu-item-bg-active: #92b773,
                l-menu-item-bg-active-1: #cde3bb,
                l-menu-item-playing-or-next-bg: #c9c9c9,
                l-menu-item-playing-or-next-cl: black,
          //dev
                l-nav-dev-bg: white,
                l-dev-button-bg: transparent,
                l-dev-button-cl: black,
                l-dev-button-bg-active: #e7e7e7,
                l-dev-button-cl-active: black,
          // question
                l-question-cl: $nobel,
                l-question-border-cl: $gray90,
                l-question-clickable-cl: black,
                l-question-hover-bg: #e7e7e7,
                l-question-ticked-bg: #ffe7dc,
                l-question-correct-bg: $primary-color,
                l-question-correct-cl: $white,
                l-question-correct-border-cl: $primary-color,
                l-question-incorrect-bg: $red,
                l-question-incorrect-cl: $white,
                l-question-incorrect-border-cl: $red,
                l-question-done-bg: $blue,
                l-question-done-cl: $white,
                l-question-done-border-cl: $blue,
                l-question-touched-bg: $gray90,
                l-question-touched-border-cl: $gray90,
                l-question-current-bg: #92c36a,
                l-question-current-border-cl: $blue,
                l-question-listening-bg: #1b5e20,
                l-question-save-failed-border-cl: $red,
                l-question-height: 0,
          // summary course
                l-summary-course-session-bg: rgba(224, 224, 224, 0.24),
                menu-top-bg: theme-color(),
                l-summary-course-feature-boxed: white,
                l-course-overview-content-cl: #19191d,
          // in progress
                l-progress-finish: rgba(146, 195, 106, 0.85),
                l-progress-improvement: rgba(239, 140, 0, 0.85),
          //rgba(240, 173, 78, 0.85),
                l-progress-lock: rgba(217, 83, 79, 0.85),
                l-progress-bad: rgba(217, 83, 79, 0.85),
          // PDF
                l-button-pdf-cl: #6d6d6d,
                l-button-pdf-cl-disable: #e0e0e0,
          // comment
                comment-main-cl: #303030,
                comment-action-hover-bg: #ededed,
                active-color: theme-color(),
                active-item-background-color: rgba($d-primary-color, 0.2),
                active-item-background-border: theme-color(),
                close-button-color: theme-color(),
                complete-icon-background-color: theme-color(),
                course-title-percent-text-color: theme-color(),
                over-deadline-color: red,
                primary-text-color: theme-color(),
                progress-color-learn: theme-color(),
                sco-background-color--hover: #e6f4f4,
                sco-background-color: #e6f4f4,
                sco-text-color: #504e4e,
                tab-background-color: #ffffff,
                tab-text-active-color: theme-color(),
                tab-text-color: #504e4e,
                tab-box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1),
                tiny-progress-color: theme-color(),
                pagination-text-color: theme-color(),
                pagination-active-text-color: #fff,
                pagination-acive-background-color: theme-color(),
          // modal login
                login-button-facebook: #3b5998,
                login-button-google: #dc4e41,
                login-background-footer: #242c42,
                login-color-footer: #95989a,
                login-color-link: #3b7adb,
                login-color-hover-forgot-password: #e13542,
                login-color-title: #272727,
                login-color-support: #f1f2ef,
          // faq-question-answer
                question-content: #242c42,
                question-tie: #e72a2a,
                answer-tie: #4a90e2,
                answer-link: #00ace9,
                border-expand: #8fdd2d,
          //question
                hover-highlight-question: #5ac743,
                status-success: theme-color(),
                status-approved: #096e07,
                status-queued: #808080,
                status-rejected: #ff0000,
                top-banner-temis-dashboard: $d-primary-color,
                theme-background-color: rgba($d-primary-color, 0.1),
        ),
);

@font-face {
  font-family: 'Google Sans';
  src: url('/fonts/google-sans/GoogleSans-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Google Sans';
  src: url('/fonts/google-sans/GoogleSans-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Google Sans';
  src: url('/fonts/google-sans/GoogleSans-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Google Sans';
  src: url('/fonts/google-sans/GoogleSans-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Google Sans';
  src: url('/fonts/google-sans/GoogleSans-Bold.ttf');
  font-weight: 700;
}
