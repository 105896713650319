@import 'layouts/theme.scss';

.common-antd-table {
  &--disabled {
    opacity: 0.3;
    cursor: not-allowed;
    pointer-events: none;
  }
  .highlight-row {
    background: cornsilk;
  }
  .highlight-danger-row {
    @include themify($themes) {
      background: themed('light-red');
    }
  }
  .deleted-row {
    @include themify($themes) {
      background: themed('light-grey');
    }
  }
  .table-cell-editable {
    cursor: pointer;
    text-decoration: underline;
  }
}

.ant-table-wrapper {
  &.learn-default {
    .ant-table,
    .ant-table-fixed-left {
      @include themify($themes) {
        background-color: themed('light');
      }
      .ant-table-body >,
      .ant-table-body-inner > {
        table {
          border-collapse: separate;
          border-spacing: 3px;
          border: none;
          th {
            font-weight: 500;
          }
          td {
            font-weight: 300;

            @include themify($themes) {
              border: 1px solid themed('light-grey');
            }

            &.stt {
              padding: 2px !important;
            }
          }
          th, td {
            padding: 12px 8px !important;
            @include themify($themes) {
              color: themed('text-secondary-color');
            }
          }
          tr {
            border: 0 !important;
            th {
              @include themify($themes) {
                background-color: themed('active-item-background-color');
              }
              .ant-table-column-sorter {
                display: none;
              }
            }
            &:hover {
              th, td {
                @include themify($themes) {
                  background-color: themed('active-item-background-color') !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.table-config-columns {
  .ant-table-placeholder {
    display: none;
  }

  * {
    font-size: 13px;
  }
}

.quick-config {
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  &__config-area {
    @include themify($themes) {
      border: 1px solid themed('light-grey');
    }
  }
}
