@import "layouts/theme.scss";

@mixin button($background) {
  background-color: $background;
  border: 1px solid $background;
  @include themify($themes){
    color: themed('light');
  }
  display: flex;
  width: 100%;
  padding: 5px 20px;
  margin-top: 0;
  vertical-align: middle;
  min-height: 40px;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  &:hover {
    color: $background;
    @include themify($themes){
      background-color: themed('light');
    }
  }
}

.etec-login-form {
  padding: 60px 75px 0 75px;
  width: 100%;
  .content-dialog {
    width: 520px !important;
  }

  h3 {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 20px;
    transform: translateY(-16px);
    text-transform: uppercase;
    @include themify($themes){
      color: themed('dark');
    }
  }
  h3,
  input {
    display: block;
  }
  .indicator {
    width: 30px;
    transform: translateY(-16px);
    height: 3px;
    @include themify($themes){
      background-color: themed('login-form-indicator');
    }
    margin: 0;
    padding: 0;
  }
  .login-btn {
    @include themify($themes){
      background-color: themed('btn-login-bg');
      color: themed('light');
    }
    border: none;
    display: flex;
    width: 100%;
    padding: 5px 25px;
    margin-top: 0;
    vertical-align: middle;
    min-height: 45px;
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }
  a {
    cursor: pointer;
  }
  .remember-me-panel {
    margin: 20px 0;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  .are-you-forgot-pass {
    text-align: center;
    margin: 24px;
  }
  input[type='checkbox'] {
    width: 15px;
    height: 15px;
  }
  select {
    height: 46px;
    background: white;
    opacity: 1;
    padding: 5px;
    width: 100%;
    @include themify($themes){
      border: 1px solid themed('dark');
    }
    margin-top: 10px;
  }
  @mixin socialAuthor($background) {
    padding: 8px;
    width: 100%;
    color: white;
    font-size: 16px;
    display: inline-block;
    background: $background;
    border: none;
    margin-right: 18px;
  }
  .my-facebook-button-class {
    @include themify($themes){
      @include socialAuthor(themed('login-button-facebook'));
    }
  }
  .googleplus {
    @include themify($themes){
      @include socialAuthor(themed('login-button-google'));
    }
  }
  .footer {
    min-height: 70px;
    margin-top: 64px;
    text-align: center;
    margin-left: -75px;
    padding: 0;
    margin-right: -75px;
    @include themify($themes){
      background-color: themed('login-background-footer');
      color: themed('login-color-footer');
    }
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
    span {
      @include themify($themes){
        color: themed('light');
      }
      cursor: pointer;
    }
  }
  a {
    @include themify($themes){
      color: themed('login-color-link');
    }
  }
}

.evn-login-form {
  @include themify($themes){
    background-color: themed('light');
  }

  a {
    &:hover {
      @include themify($themes){
        color: themed('light');
      }
    }
  }
  .forgot-password:hover {
    @include themify($themes){
      color: themed('login-color-hover-forgot-password');
    }
  }
  & .indicator {
    @include themify($themes) {
      background-color: themed('btn-login-bg');
    }
  }
  & .login-btn {
    @include themify($themes) {
      background: themed('btn-login-bg');
    }
  }

  .title {
    text-align: center;
    height: 21px;
    @include themify($themes){
      color: themed('login-color-title');
    }
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0 0 10px 0;
  }
  .text-field {
    margin-top: -20px;
  }
  .action {
    display: flex;
    margin: 0 auto;
    text-align: center;
    flex-direction: column;
    .forgot-password {
      cursor: pointer;
    }
  }
  .btn {
    border: none;
    width: 150px;
    min-width: 100px;
    margin: 13px auto;
    height: 38px;
    border-radius: 2px;
    @include themify($themes) {
      background-color: themed('btn-login-bg');
      color: themed('light');
    }
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    &:hover {
      color: white;
    }
  }
}

@media screen and (max-width: 555px) {
  .etec-login-form {
    padding: 60px 35px 0 35px;
    width: 100%;
    .footer {
      margin-left: -35px;
      margin-right: -35px;
    }
  }
}

.vt-login-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 145, 140, 0.4);
  }
}

.vt-login-header {
  position: relative;
  z-index: 1;
  @include themify($themes) {
    background-color: themed('light');
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 5px 0;
  }

  .logo-link {
    img {
      max-height: 50px;
    }
  }

  .wrap-support-login {
    > a {
      @include themify($themes) {
        color: themed('secondary-color');
      }
      &:hover {
        @include themify($themes) {
          color: themed('primary-color');
        }
      }
    }
  }

  .support-login {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 16px;
    padding: 10px;

    .support-phone,
    .key {
      @include themify($themes) {
        color: themed('primary-color');
      }
    }
    .support-title {
      margin-right: 15px;

      @include themify($themes) {
        color: themed('text-secondary-color');
      }
    }
    .support-phone-key {
      @include themify($themes) {
        color: themed('text-muted-color');
      }
    }
  }

  .question-answer {
    @include themify($themes) {
      color: themed('secondary-color');

      &:hover {
        color: themed('primary-color');
      }
    }
  }

  .question-answer,
  .support-email {
    font-weight: initial;
  }

  @media (min-width: 576px) {
    .wrap-support-login {
      display: flex;
      align-items: center;
      > a {
        margin-left: 15px;
      }
    }
  }
  @media (min-width: 768px) {
    .support-login {
      display: flex;
    }

    .support-email,
    .support-phone-key,
    .question-answer {
      margin-left: 15px;
    }
  }

  @media (max-width: 768px) {
    .support-login {
      font-size: 13px;
    }
  }
}

.support-email {
  @include themify($themes) {
    color: themed('primary-color') !important;
  }
}

.vt-login-content {
  position: relative;
  z-index: 1;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px 10px;
  &__wrapper {
    height: 100%;
    position: relative;
  }
  @media (min-width: 992px) {
    min-height: calc(100vh - 107px); // Fix in IE 100vh - header height(60) - footer height(47)
  }
}
.vt-login-footer {
  text-align: center;
  position: relative;
  z-index: 1;
  padding: 15px 0;
  @include themify($themes) {
    color: themed('light');
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.vt-login-form {
  &__wrapper {
    padding: 20px 25px;
    max-width: 570px;
    @include themify($themes) {
      background-color: themed('light');
    }
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  }

  .indicator {
    width: 30px;
    transform: translateY(-16px);
    height: 3px;
    @include themify($themes) {
      background-color: themed('btn-primary-bg');
    }
    margin: 0;
    padding: 0;
  }
  .login-btn {
    @include themify($themes) {
      @include button(themed('btn-primary-bg'));
    }
  }
  .login-btn-with-viettel-sso {
    @include themify($themes) {
      @include button(themed('secondary-color'));
    }
    height: 50px;
    text-transform: initial !important;
    font-size: 18px !important;
    font-weight: 500;
    border-radius: 3px;
  }
  .remember-me-panel {
    margin: 20px 0;
  }
  @mixin socialAuthor($background) {
    padding: 8px;
    width: 100%;
    color: white;
    font-size: 16px;
    display: inline-block;
    background: $background;
    border: none;
    margin-right: 18px;
  }
  .my-facebook-button-class {
    @include themify($themes){
      @include socialAuthor(themed('login-button-facebook'));
    }
  }
  .googleplus {
    @include themify($themes){
      @include socialAuthor(themed('login-button-google'));
    }
  }
  .footer {
    min-height: 70px;
    margin-top: 64px;
    text-align: center;
    margin-left: -75px;
    padding: 0;
    margin-right: -75px;
    @include themify($themes){
      background-color: themed('login-background-footer');
      color: themed('login-color-footer');
    }
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
    span {
      color: white;
      cursor: pointer;
    }
  }

  .support-login {
    @include themify($themes){
      background-color: themed('login-color-support');
    }
    padding: 5px;
    a {
      font: {
        size: 24px;
      }
      @include themify($themes) {
        color: themed('a-primary-cl');
      }
      margin: 5px;
      transition: 0.3s;
      &:hover {
        text-decoration: underline !important;
      }
    }
  }
  a {
    font-size: 14px;
    @include themify($themes) {
      color: themed('blue-color');
    }
    &:hover {
      @include themify($themes) {
        color: themed('secondary-color');
      }
    }
  }
  input[type='text'],
  input[type='password']{
    height: 50px;
    padding: 8px 20px;
    font-size: 14px;
    outline: none !important;
    font-weight: 500;
    @include themify($themes) {
      border: 1px solid themed('text-muted-color');
    }
    border-radius: 3px;
    &::placeholder {
      @include themify($themes) {
        color: themed('text-muted-color');
      }
    }

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  select {
    height: 50px;
    padding: 8px 20px;
    font-size: 14px;
    width: 100%;
    @include themify($themes) {
      border: 1px solid themed('text-muted-color');
    }
  }

  &__title {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    margin-bottom: 22px;
    @include themify($themes) {
      color: themed('text-secondary-color');
    }
  }
  &__title-main {
    margin-bottom: 5px;
  }
  &__item {
    margin-bottom: 15px;
    .text-input-wrapper {
      .ant-form-item {
        position: relative;
        .ant-form-item-label {
          margin-bottom: 3px;
          line-height: 1;
        }
      }
      &.focus {
        .ant-form-item {
          input[type='text'],
          input[type='password']{
            @include themify($themes) {
              background-color: themed('background-active-color');
            }
          }
        }
      }
    }
  }
  &__guide-and-forgot-password {
    display: flex;
    margin-bottom: 25px;
    .forgot-password {
      margin-left: auto;
    }
  }
  &__login-button {
    &.btn {
      width: 100%;
      height: 50px !important;
      font-size: 18px !important;
    }
  }
  &__register {
    text-align: center;
    margin-top: 20px;
  }

  @media (min-width: 576px) {
    &__wrapper {
      padding: 30px 50px;
    }
  }
  @media (min-width: 768px) {
    &__wrapper {
      padding: 60px 100px;
    }
    &__title {
      font-size: 20px;
    }
  }
}
