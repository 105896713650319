@import '../common/font-icon/material-icon/stylesheet.scss';
@import './learning/stylesheet.scss';
@import 'layouts/theme.scss';
@import 'components/style-override/style.scss';

html,
body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}

.ui-root-layout {
  position: relative;
}

a {
  color: #000;
}

*:-webkit-autofill,
*:-webkit-autofill:hover,
*:-webkit-autofill:focus,
*:-webkit-autofill:active {
  border: none !important;
  background-color: inherit !important;
  -webkit-text-fill-color: inherit !important;
  -webkit-box-shadow: inset 0 0 0 9999px white !important;
}

.truncated-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.truncated-text:hover {
  overflow: visible;
  white-space: normal;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify !important;
}

.text-transform {
  text-transform: uppercase;
}

.text-bold {
  font-weight: bold !important;
}

.text-muted {
  color: #9b9b9b;
}

.text-primary {
  @include themify($themes) {
    color: themed('primary-color');
  }
}

.text-secondary {
  @include themify($themes) {
    color: themed('secondary-color');
  }
}

.link {
  cursor: pointer !important;

  @include themify($themes) {
    color: themed('primary-color');
  }

  &.underline {
    text-decoration: underline;
  }

  &:hover {
    @include themify($themes) {
      color: themed('secondary-color') !important;
    }
  }

  &:focus,
  &:active,
  &:visited {
    @include themify($themes) {
      color: themed('primary-color');
    }
  }

  &.link-text {
    color: inherit;

    &:hover {
      @include themify($themes) {
        color: themed('secondary-color');
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buton-delete {
  :hover {
    color: #ca3712;
  }
}

table td,
table th {
  white-space: normal !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
}

table .highlight-row {
  background-color: rgba(56, 152, 150, 0.73);
}

.form-validate-error {
  margin-top: 10px;
  @include themify($themes) {
    color: themed('danger');
  }
}

.icon-button {
  z-index: 10;
}

ul.item-table-panel {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 0 0 0 5px;
  }
}

.default-login-form-wrapper {
  .content-dialog {
    width: 406px !important;
  }

  .default-login-form {
    padding: 30px 60px;

    h3 {
      margin-top: 0;
    }

    h3,
    input {
      display: block;
    }

    input,
    input:hover,
    input:focus {
      border: 1px solid #d9d9d9 !important;
      padding: 6px 12px !important;
      width: 100%;
      margin-bottom: 18px;
      height: auto !important;
    }

    .login-btn {
      background: #dcab18;
      border: none;
      color: #fff;
      padding: 5px 25px;
      margin-top: 0;
      font-size: 14px;
      cursor: pointer;
    }

    a {
      cursor: pointer;
    }
  }
}

.etec-login-form-wrapper {
  .content-dialog {
    width: 520px !important;
    top: -100px;
  }
}

@media screen and (max-width: 555px) {
  .etec-login-form-wrapper {
    .content-dialog {
      width: 350px !important;
      top: -100px;
    }
  }
}

@media screen and (max-width: 480px) {
  .default-login-form-wrapper {
    .content-dialog {
      width: 90% !important;
    }
  }
}

@media screen and (max-width: 375px) {
  .etec-login-form-wrapper {
    .content-dialog {
      width: 310px !important;
      top: -100px;
    }
  }
}

.white-box {
  background: white;
  padding: 20px;
  margin-bottom: 10px;

  form {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

button {
  span {
    text-transform: none !important;
    font-weight: normal !important;
  }
}

.m-p-0 {
  margin: 0;
  padding: 0;
}

.wrap-td {
  white-space: unset !important;
  text-overflow: unset !important;
}

a {
  text-decoration: none;
}

.searchResultsDeletedRow {
  background-color: orange !important;
}

.display-none {
  display: none;
}

.displayFlex {
  display: flex;
}

.flex-container {
  display: flex !important;
  flex-wrap: nowrap !important;
  align-items: stretch !important;

  .flex-item {
    padding: 2px !important;
    flex-grow: 1 !important;
  }

  .submit {
    width: 100px;
  }
}

.flex-container-wrap {
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  flex: auto;

  .flex-item {
    display: inline-block;
    padding: 2px;
    flex-grow: 1;
  }
}

.table-border {
  table {
    width: 100%;
  }

  table,
  th,
  td {
    border: 1px solid rgb(224, 224, 224);
  }
}

.table-body-align-top {
  td {
    vertical-align: top;
    padding-top: 10px;
  }
}

.table-cell-list {
  padding-left: 15px;
}

.white {
  color: white;
}

.white-background {
  background-color: white;

  .elementGroup__title {
    //Hack hiển thị chữ đen nền trắng
    color: rgba(0, 0, 0, 0.65) !important;
  }
}

.whitebox {
  background: white;
  padding: 10px 15px;
}

.dialog-content {
  background: #f0f2f5 !important;

  .dialog-content {
    padding-top: 24px !important;
  }
}

.elementGroup {
  margin-bottom: 10px;
}

.elementGroupBody {
  padding: 10px 15px 20px;
  //border: 1px solid #dddddd; /* các phần search ở ngoài danh sách cũng bị border, nên tạm thời bỏ đi */
}

.cleanPadding {
  padding: 0 !important;
}

@for $size from 0 through 100 {
  $attributes: 'padding' 'p', 'margin' 'm';

  $sides: 'top' 't', 'right' 'r', 'bottom' 'b', 'left' 'l';

  @each $a in $attributes {
    .#{nth($a, 2)}-#{$size} {
      #{nth($a, 1)}: #{$size}px !important;
    }

    @each $s in $sides {
      .#{nth($a, 2)}-#{nth($s, 2)}-#{$size} {
        #{nth($a, 1)}-#{nth($s, 1)}: #{$size}px !important;
      }
    }
  }
}

.ui-cursor-pointer {
  cursor: pointer;
}

//======== WE COPY THESE CLASSES FROM BOOTSTRAP V4 ==============//

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-flex-reverse {
  flex-direction: row-reverse !important;
}


.d-inline-flex {
  display: inline-flex !important;
}

.d-flex-wrap {
  flex-wrap: wrap;
}

.d-flex-column {
  flex-direction: column;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

//======== FINISH COPY FROM BOOTSTRAP V4 ==============//

$aligns: (left, right, center);
@each $align in $aligns {
  .text-#{$align} {
    text-align: #{$align};
  }
}

.text-white {
  color: #fff;
}

.text-title-dashboard {
  color: #515151;
}

.border-round {
  border-radius: 5px;
}

.border-top {
  border-top: 1px solid #eee;
}

.border {
  border: 1px solid #eee;
}

.border-bottom {
  border-bottom: 1px solid #eee;
}

.border-left {
  border-left: 1px solid #eee;
}

.border-right {
  border-right: 1px solid #eee;
}

.border-right-3 {
  border-right: 3px solid #eee;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.ant-select-dropdown {
  z-index: 2500;
}

.ant-notification {
  z-index: 3000;
}

.ant-tooltip {
  z-index: 3500;
}

.w-100 {
  width: 100%;
}

.sticky-card {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

.display-contents {
  display: contents;
}

.user-select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.table-border-round {
  background: none !important;

  table {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    overflow: hidden;

    thead {
      tr {
        th {
          background: #eeeeee;
          color: #4a4a4a;
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;

          &:first-child {
            padding-left: 15px !important;
          }

          &:last-child {
            padding-right: 15px !important;
          }
        }
      }
    }

    td {
      background: #fff;

      &:first-child {
        padding-left: 15px !important;
      }

      &:last-child {
        padding-right: 15px !important;
      }
    }
  }
}

.link:hover {
  cursor: default;
}

.z-index-1 {
  z-index: 1;
}

.z-index-1000 {
  z-index: 1000;
}

.has-error.has-feedback .ant-form-item-children-icon {
  margin-top: 10px;
}

.ant-checkbox-inner {
  border-color: #abd7ff !important;
}

.break-word {
  word-break: break-word;
  white-space: normal;
}

.mix-blend-difference {
  mix-blend-mode: difference;
}

$label-color: rgba(0, 0, 0, 0.8);
.input-text-label,
.date-time-picker-label {
  color: $label-color !important;
}

.ant-form-item {
  margin-bottom: 10px !important;
}

.ant-form-item-label,
.input-text-label {
  line-height: 36px;
  margin-bottom: -5px;

  .ant-form-item-no-colon {
    color: $label-color !important;
  }
}

@mixin button($color) {
  outline: none;
  padding: 10px 18px;
  line-height: 17px;
  font-weight: 500;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  font-size: 14px;
  text-decoration: none;
  height: auto;
  box-shadow: none;
  text-shadow: none;

  @include themify($themes) {
    border-radius: themed('l-button-primary-border-radius-dimen');
    color: themed('light');
    border: 1px solid themed($color);
    background-color: themed($color);
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    pointer-events: none;

    @include themify($themes) {
      background-color: themed('text-muted-color') !important;
      color: themed('light') !important;
      border-color: themed('text-muted-color') !important;
    }
  }

  &:hover:not(.disabled),
  &:hover:not([disabled]) {
    @include themify($themes) {
      border: 1px solid themed($color);
      color: themed($color);
    }
    background-color: transparent;
  }

  &.btn-outline {
    @include themify($themes) {
      border: 1px solid themed($color);
      color: themed($color);
    }
    background-color: transparent;

    &:hover {
      @include themify($themes) {
        border: 1px solid themed($color);
        background-color: themed($color);
        color: themed('light');
      }
    }
  }

  &.btn-small {
    padding: 5px;
    font-size: 13px;
  }
}

.btn {
  @include button('text-muted-color');
}

.btn-primary {
  @include button('primary-color');
}

.btn-secondary {
  @include button('secondary-color');
}

.btn-disabled {
  @include button('light-grey');
}

.btn-tertiary {
  @include button('danger');
}

.email {
  font-family: Arial, sans-serif;
}

.text-danger {
  color: red;
}

.full-parent-height {
  height: 100%;
  @media screen and (max-width: 768px) {
    height: auto;
  }
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.height-auto {
  height: auto !important;
}

.icon-assign-secondary {
  @include themify($themes) {
    color: themed('secondary-color');
  }
}

.text-faded {
  opacity: 0.5;
}

.container-no-padding {
  .container-fluid {
    padding: 0;
  }
}

.menu-container {
  padding-top: 15px;

  p.title {
    @include themify($themes) {
      color: themed('light');
    }

    margin-bottom: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.2;
  }

  .ant-dashboard-menu {
    width: 100%;
    border: none;
    background: none;
    padding-right: 20px;

    a {
      @include themify($themes) {
        color: themed('gray77');
      }

      border-bottom: 3px solid transparent;

      font: {
        weight: 500;
        size: 14px;
      }
    }

    li {
      border-bottom: 0;
      top: 0;
      margin-top: 0;
      padding: 0 20px 0 0;

      &:hover {
        border-bottom: 0;
        margin-top: 0;

        a {
          @include themify($themes) {
            border-bottom: 3px solid themed('secondary-color');
            color: themed('light') !important;
          }

          &:before {
            bottom: -3px;
          }
        }
      }
    }

    .ant-menu-item-selected {
      background: none;
      border-bottom: 0;

      a {
        @include themify($themes) {
          color: themed('light');
          border-bottom: 3px solid themed('secondary-color');
        }
      }
    }

    .ant-menu-submenu-title {
      display: flex;

      & > span {
        font-size: 40px;

        @include themify($themes) {
          color: themed('light');
        }
      }
    }

    .ant-menu-submenu-horizontal.ant-menu-submenu-selected {
      @include themify($themes) {
        border-bottom: 3px solid themed('secondary-color');
      }
    }
  }

  .ant-menu-horizontal {
    line-height: 35px;
  }

  @media (max-width: 768px) {
    padding: 20px 0 0 0;

    p.title {
      margin-bottom: 0;
    }

    .ant-dashboard-menu {
      .ant-menu-item {
        a {
          font-size: 14px;
        }
      }

      .ant-menu-submenu-title {
        padding: 0 10px;
      }
    }
  }
}

.system-message {
  margin: 10px;
  text-align: center;
}

.alert-message {
  @include themify($themes) {
    color: themed('danger');
  }
}

.view-action-link {
  @include themify($themes) {
    color: themed('secondary-color');
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.mui-paper-no-box-shadow {
  .mui-paper-container {
    box-shadow: none !important;
  }
}

.mui-paper-no-padding {
  .mui-paper-container {
    padding: 0 !important;
  }
}

.visibility-hidden {
  visibility: hidden;
}

.pointer-event-none,
.disabled {
  pointer-events: none;
}

.font-size-medium {
  font-size: 16px;
}

.font-size-large {
  font-size: 18px;
}

.font-size-small {
  font-size: 12px;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.content-thumb-vertical {
  @include themify($themes) {
    background-color: themed('l-thumb-vertical-bg');
  }
  width: 8px !important;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
  }
}

.no-bg {
  background: unset !important;
}

.text-success {
  @include themify($themes) {
    color: themed('success');
  }
}

.max-width-300 {
  max-width: 300px;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}

.fit-content {
  img {
    object-fit: cover;
    width: 100%;
  }
}

.fit-image-content {
  img {
    object-fit: cover;
    width: 100%;
  }
}

.ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.45);
}

.bg-transparent {
  background-color: transparent !important;
}

.full-width {
  width: 100%;
}
