@import 'layouts/theme.scss';

.lecture-pdf {
  background-color: white;

  &__navigation {
    position: absolute;
    width: 100%;
    color: $black;
    background-color: #EEEEEE;

    &--fullscreen {
      bottom: 0;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3px;
    padding-bottom: 3px;

    @media screen and (min-width: 769px) {
      position: relative;
    }
  }

  &__navigation-rotate-buttons {
    display: flex;
  }

  &__navigation-rotate-button {
    border: none;
    background: transparent;
    font-size: 26px;
    outline: none;
    @include themify($themes) {
      color: themed('l-button-pdf-cl');
    }
  }

  &__rotation-zoom {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    right: 0;
  }

  &__zoom, &__rotation {
    position: relative;
  }

  &__zoom {
    display: flex;
    align-items: center;

    span {
      display: flex;

      .material-icons {
        font-size: 20px;
      }
    }
  }

  &__rotation {
    top: 1px;
  }

  &--fullscreen {
    height: 100vh !important;
    width: 70vw !important;
    max-width: 1000px;
  }

  &__fullscreen-button {
    position: absolute;
    z-index: 1;
    background-color: transparent;
    border: none;
    font-size: 35px;
    outline: none;
  }

  & .button-zoom {
    outline: none;
    box-shadow: none;
    border-color: transparent;
  }

  & .button[disabled], .button-zoom[disabled] {
    border-color: transparent;
    box-shadow: none;
    opacity: .2;
    cursor: not-allowed;
  }

  & .react-pdf {
    &__Document > div {
      @include themify($themes) {
        background-color: themed('gray90');
      }
    }
  }
  @media screen and (min-width: 769px) {
    &__content {
      flex: 1 1 auto;
      height: auto !important;
    }
  }

  @media screen and (max-width: 768px) {
    &__navigation {
      justify-content: flex-start;
    }
  }
}
