/* Text */
/* Border */
/* Background */
/* Button */
/* Menu */
@font-face {
  font-family: 'Google Sans';
  src: url("/fonts/google-sans/GoogleSans-Regular.ttf");
  font-weight: 400; }

@font-face {
  font-family: 'Google Sans';
  src: url("/fonts/google-sans/GoogleSans-Medium.ttf");
  font-weight: 500; }

@font-face {
  font-family: 'Google Sans';
  src: url("/fonts/google-sans/GoogleSans-Bold.ttf");
  font-weight: 700; }

@font-face {
  font-family: 'Google Sans';
  src: url("/fonts/google-sans/GoogleSans-Medium.ttf");
  font-weight: 500; }

@font-face {
  font-family: 'Google Sans';
  src: url("/fonts/google-sans/GoogleSans-Bold.ttf");
  font-weight: 700; }

/*
$themes: map-merge(
  map-merge(
    map-merge(
      map-merge(
        map-merge(map-merge(map-merge($default, $sb), $evn), $msi),
        $vt
      ),
      $blue
    ),
    $hpu2
  ),
  $gj
);
*/
.modal-new-virtual-classes .ant-modal-title {
  padding-right: 15px; }

.modal-new-virtual-classes .ant-card-bordered {
  border: none; }

.theme-default-light .modal-new-virtual-classes .ui-form-control button {
  background-color: !important;
  border: !important; }

.theme-default-dark .modal-new-virtual-classes .ui-form-control button {
  background-color: !important;
  border: !important; }

.theme-exam .modal-new-virtual-classes .ui-form-control button {
  background-color: !important;
  border: !important; }

.theme-vt-light .modal-new-virtual-classes .ui-form-control button {
  background-color: #fe7a3d !important;
  border: #fe7a3d !important; }

.theme-vt-dark .modal-new-virtual-classes .ui-form-control button {
  background-color: #d44401 !important;
  border: #d44401 !important; }

.theme-default-light .modal-new-virtual-classes .ui-form-control button:hover {
  color: !important; }

.theme-default-dark .modal-new-virtual-classes .ui-form-control button:hover {
  color: !important; }

.theme-exam .modal-new-virtual-classes .ui-form-control button:hover {
  color: !important; }

.theme-vt-light .modal-new-virtual-classes .ui-form-control button:hover {
  color: #ffffff !important; }

.theme-vt-dark .modal-new-virtual-classes .ui-form-control button:hover {
  color: #ffffff !important; }

.virtual-classes-name {
  margin-bottom: 20px; }

.layout-new-session__content {
  position: relative; }
  .layout-new-session__content .disable_time {
    position: absolute;
    top: -20px;
    width: 100%;
    height: 120%;
    z-index: 99; }
  .layout-new-session__content .icon-arrow {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }

.layout-new-session .date-time .ant-calendar-picker {
  min-width: 100%; }
  .layout-new-session .date-time .ant-calendar-picker-clear, .layout-new-session .date-time .ant-calendar-picker-icon {
    right: 10px; }

@media screen and (max-width: 575px) {
  .layout-new-session .md-full-width {
    display: flex;
    align-items: stretch; }
  .layout-new-session .ant-duration-time-picker-wrapper {
    display: flex;
    align-items: stretch; }
    .layout-new-session .ant-duration-time-picker-wrapper .ant-form-item {
      flex-direction: column;
      display: flex;
      align-items: stretch; }
      .layout-new-session .ant-duration-time-picker-wrapper .ant-form-item .ant-form-item-control-wrapper {
        margin-top: auto; }
  .layout-new-session .ant-form-item {
    margin-bottom: 0; }
  .layout-new-session .icon-arrow {
    bottom: 16px;
    transform: translateX(-50%);
    top: inherit; }
  .layout-new-session label {
    font-size: 12px; } }

.virtual-classes-public .ant-form-item {
  margin-bottom: 5px; }

@media screen and (max-width: 575px) {
  .virtual-classes-name {
    margin-bottom: 0; }
    .virtual-classes-name label span {
      font-size: 12px; }
  .ant-form-item label {
    margin-top: 15px; } }
