@import './../../defined.scss';
@import 'layouts/theme.scss';

.learn-lecture {
  height: 100%;
  display: flex;
  flex-direction: column;

  &.show-discussion {
    .lecture-pdf {
      min-height: auto !important;
      + .learn-lecture__content {
        flex-grow: initial;
        margin: 0 -10px;
        padding: 0;
      }
    }
    @media (min-width: 768px) {
      .lecture-pdf {
        + .learn-lecture__content {
          margin: 0;
          padding: 20px;
        }
      }
    }
  }

  &__main {
    flex-shrink: 0;
    position: relative;
    height: $l-screen-height;

    @media screen and (min-width: 769px) {
      flex: 1 1;
      height: auto;
      display: flex;
      flex-direction: column;
    }
  }

  &__content {
    background: white;
    flex-grow: 1;
    padding: 20px;

    img {
      max-width: 100%;
    }
  }
  &__text-large {
    p {
      font-size: 16px;
    }
  }

  @media screen and (min-width: 769px) {
    flex: 1 0 auto;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    &__content {
      img {
        width: 100%;
      }
    }
  }
}

.lecture-image {
  height: 100%;
  padding: 20px;
  text-align: center;
  background-color: #ffffff;

  img {
    width: auto;
    height: 100%;
  }
}

.lecture-audio,
.lecture-link {
  height: 100%;
  @include themify($themes) {
    background-color: themed('light');
  }
}

.lecture-video {
  height: 100%;

  @media screen and (min-width: 769px) {
    display: flex;
    height: auto;
    flex: 1 1 auto;
    flex-direction: column;
    @include themify($themes) {
      background-color: themed('light');
    }

    .common-overlay-component__wrapper {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      height: auto;
      align-items: center;
      max-height: 100%;

      .learn-full-screen {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        max-height: 100%;
        width: 100% !important;
        height: 100% !important;

        video {
          outline: none;
          display: flex;
          flex: 1 1 auto;
        }

        & > div {
          display: flex;
          flex: 1 1 auto;
          flex-direction: column;

          iframe {
            flex: 1 1 auto;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 20px;
  }
}

.learn-lecture-wrapper {
  width: 100%;
  flex: 1;
  @include themify($themes) {
    background-color: themed('light');
  }

  &.height-unset {
    height: unset !important;
  }

  &__buttons {
    background-color: #272525;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @media screen and (min-width: 769px) {
    display: flex;

    & > .nav-scroll {
      height: auto !important;
    }
  }

  &__no-content {
    @include themify($themes) {
      background-color: themed('light');
    }

    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;

    @media screen and (max-width: 768px) {
      width: 100%;
      text-align: center;
      font-size: 16px;
    }
  }
}

.learn-lecture-buttons {
  &__seen {
    width: 100% !important;
  }
}

@media screen and (max-width: 991px) {
  .learn-lecture-buttons {
    //position: absolute;
    //width: 300px;
    //left: calc(50% - 150px);
    &__seen {
      margin-bottom: 0 !important;
    }
  }
}
